import {Component, OnDestroy, OnInit} from '@angular/core';
import {SideNavService} from '../../../Services/global/side-nav.service';
import {AuthGuard} from '../../../Services/guard';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Authorities} from '../../../Services/data/constants';
import {Subscription} from 'rxjs';
import {BroadcastService} from '../../../Services/broadcast/broadcast.service';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmPasswordModalComponent} from './confirm-password-modal/confirm-password-modal.component';
import {ModalCreatePasswordComponent} from './modal-create-password/modal-create-password.component';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './NewHeader.component.html',
  styleUrls: ['./NewHeader.component.scss']
})
export class NewHeaderComponent implements OnInit, OnDestroy {
  authorities = Authorities;
  isRouterUrl;
  loggedInSubscription: Subscription;
  isLoggedIn = false;
  subscriptionQureryParam: Subscription;

  constructor(public sideNavService: SideNavService,
              public authGuard: AuthGuard,
              private broadcastService: BroadcastService,
              private router: Router,
              private route: ActivatedRoute,
              public dialog: MatDialog) {
  }

  ngOnInit() {
    this.isLoggedIn = this.authGuard.isAuthoritiesApiPartner();
    this.loggedInSubscription = this.broadcastService.events.subscribe((event: any) => {
      if (event.type === 'ACCESS_TOKEN_LOGGED_IN') {
        this.isLoggedIn = event.type === 'ACCESS_TOKEN_LOGGED_IN';
      }
      if (event.type === 'LOGGED_OUT') {
        this.isLoggedIn = false;
      }
    });
    $('app-main').toggleClass('form-open');
    this.getRouterUrl(this.router.url);
    this.router.events
    .subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.getRouterUrl(event.url);
      }
    });
    this.autoOpenDialogLogin();
  }

  getRouterUrl = (routerUrl: string) => this.isRouterUrl = routerUrl.includes('forgot-password');


  public toggleSidebar() {
    this.sideNavService.sidenavOpen = !this.sideNavService.sidenavOpen;
  }


  redirectAfterEmailVerification() {
    if (this.authGuard.isAuthenticated() && this.authGuard.isAuthoritiesApiPartner()) {
      this.router.navigate(['/account/loyalize-user/transactions']);
      return;
    }
    this.router.navigate(['/']);
  }

  ngOnDestroy(): void {
    if (this.loggedInSubscription) {
      this.loggedInSubscription.unsubscribe();
    }
    if (this.subscriptionQureryParam) {
      this.subscriptionQureryParam.unsubscribe();
    }
  }


  openForgotPassDialog(query) {
    const dialogRef = this.dialog.open(ConfirmPasswordModalComponent, {
      width: '800px',
      minHeight: '250px',
      panelClass: 'custom-modalbox',
      data: query.keys[0]
    });
    dialogRef.afterClosed().subscribe(data => {

    });
  }

  openCreatePasswordDialog(query) {
    const dialogRef = this.dialog.open(ModalCreatePasswordComponent, {
      width: '800px',
      minHeight: '250px',
      panelClass: 'custom-modalbox',
      data: query.keys[0]
    });
    dialogRef.afterClosed().subscribe(data => {

    });
  }

  autoOpenDialogLogin() {
    if (!this.authGuard.isAuthenticated()) {
      this.subscriptionQureryParam = this.route.queryParamMap.subscribe(params => {
        const isOpenCreateUserModalSaller = params.get('spt');
        const isOpenForgotPasswordModal = params.get('fpt');
        const homeRoute = this.router.url.includes('/');
        if (isOpenForgotPasswordModal && homeRoute) {
          this.openForgotPassDialog(params);
        }
        if (isOpenCreateUserModalSaller && homeRoute) {
          this.openCreatePasswordDialog(params);
        }
      });
    }
  }

}



