import {HttpClient, HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {catchError, switchMap, filter, take} from 'rxjs/operators';
import {AuthGuard} from '../guard';
import {TokenService} from '../auth/token.service';
import {AuthService} from '../auth';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import { AuthResponse } from 'src/app/Models/auth.response.model';

const descriptionsToCheck = [
  "Loyalize Organization is not activated",
  "Account is not activated",
  "Bad credentials",
];

@Injectable()
export class RequestInterceptorService implements HttpInterceptor {
  isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string|null>(null);

  constructor(private httpClient: HttpClient,
              public authGuard: AuthGuard,
              private tokenService: TokenService,
              private authService: AuthService) {
  }

  addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    if (!this.authGuard.isAuthenticated()
        || req.url.startsWith('https://api.sandbox')
        || req.url.startsWith('https://api.paypal.com')
        || req.url.includes('api/auth-service/oauth/token')) {
      return req;
    }
    return req.clone({setHeaders: {Authorization: 'Bearer ' + token}});
  }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    let authReq = req;
    const token = this.tokenService.getToken();
    if (token != null) {
      authReq = this.addToken(req, token);
    }
    return next.handle(authReq).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && error.status === 401 && !descriptionsToCheck.includes(error.error.error_description)) {
        if (error instanceof HttpErrorResponse && error.status === 401 && req.url.endsWith('/token')) {
          this.tokenService.signOut()
        }
        return this.handle401Error(authReq, next);
      }
      return throwError(error);
    }));
  }

  private handle401Error(authReq: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const refreshToken = this.tokenService.getRefreshToken();

      if (refreshToken) {
        return this.authService.refreshToken(refreshToken).pipe(
          switchMap((token: AuthResponse) => {
            this.isRefreshing = false;
            const accessToken = token.access_token;
            this.tokenService.saveToken(accessToken);
            this.tokenService.saveRefreshToken(token.refresh_token);
            this.refreshTokenSubject.next(accessToken);
            return next.handle(this.addToken(authReq, accessToken));
          }), catchError((err) => {
            this.isRefreshing = false;
            this.tokenService.signOut();
            return throwError(err);
          })
        );
      }
      this.isRefreshing = false;
      this.tokenService.signOut();
    }
    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap(token => {
        return next.handle(this.addToken(authReq, token));
      })
    );
  }
}
