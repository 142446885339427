import { Component, OnDestroy, OnInit } from "@angular/core";
import { UserService } from "../../../Services/auth/user.service";
import { UserModel } from "../../../Models/user.model";
import { Subscription } from "rxjs";
import { AuthGuard } from "../../../Services/guard";
import { environment } from "../../../../environments/environment";
import { Router } from "@angular/router";
import { Authorities } from "../../../Services/data/constants";
import { DocsService } from "../../../Services/docs-security/docs.service";

@Component({
  selector: "app-account",
  templateUrl: "./Account.component.html",
  styleUrls: ["./Account.component.scss"],
})
export class AccountComponent implements OnInit, OnDestroy {
  loggedUser: string;
  env = environment;
  authorities = Authorities;
  panelOpenState = false;
  userNavList = true;
  private subscriptionUserProfile: Subscription;
  private subscriptionisRoute: Subscription;

  constructor(
    private userService: UserService,
    public router: Router,
    public authGuard: AuthGuard,
    private docs: DocsService
  ) {
    this.subscriptionUserProfile = this.userService.userProfile.subscribe(
      (value: UserModel) => {
        if (value) {
          this.loggedUser = value.organizationName;
        }
      }
    );
  }

  ngOnInit(): void {
    this.loggedUser = localStorage.getItem("organizationName");
  }

  openDocumentation(event) {
    this.docs.openDocumentation(event);
  }

  openCloseSidebar() {
    this.userNavList = !this.userNavList;
    if (!this.userNavList) this.panelOpenState = false;
  }
  setExpansionPanel() {
    if (this.panelOpenState) this.userNavList = true;
  }
  openSupport() {
    this.userNavList = true;
    this.panelOpenState = true;
  }

  ngOnDestroy(): void {
    if (this.subscriptionUserProfile) {
      this.subscriptionUserProfile.unsubscribe();
    }
    if (this.subscriptionisRoute) {
      this.subscriptionisRoute.unsubscribe();
    }
  }
}
