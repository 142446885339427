<div class="main-container">
  <div class="content-container bg-white final-receipt-page">
    <div fxLayout="row wrap" class="custom-container">
      <div
        class="left-container"
        [ngClass]="{ openLeft: userNavList, closeLeft: !userNavList }"
      >
        <div class="user-nav-list">
          <div class="display-img">
            <img
              *ngIf="!userNavList"
              class="logo"
              style="width: 52px"
              alt="Logo"
              src="../../../../assets/white_logo.svg"
            />
            <img
              *ngIf="userNavList"
              class="logo"
              style="width: 166px"
              alt="Logo"
              src="../../../../assets/loyalize_white_logo.svg"
            />
          </div>
          <mat-list role="list" class="listitem">
            <mat-list-item role="listitem">
              <a
                [routerLink]="['/account/loyalize-user/transactions']"
                routerLinkActive="tab-active"
                class="list-item"
              >
                <img
                  src="assets/images/right-sidebar/trx.svg"
                  class="custom-icons"
                />
                <span
                  *ngIf="userNavList"
                  [ngClass]="{ textAppear: userNavList }"
                  >Transactions</span
                >
              </a>
            </mat-list-item>
            <mat-list-item role="listitem">
              <a
                [routerLink]="['/account/loyalize-user/click-data']"
                routerLinkActive="tab-active"
                class="list-item"
              >
                <img
                  src="assets/images/right-sidebar/click.svg"
                  class="custom-icons click"
                />
                <span
                  *ngIf="userNavList"
                  [ngClass]="{ textAppear: userNavList }"
                  >Click Data</span
                >
              </a>
            </mat-list-item>
            <mat-list-item role="listitem">
              <a
                [routerLink]="['/account/loyalize-user/sku-details']"
                routerLinkActive="tab-active"
                class="list-item"
              >
                <img
                  src="assets/images/right-sidebar/sku-details.svg"
                  class="custom-icons sku"
                />
                <span
                  *ngIf="userNavList"
                  [ngClass]="{ textAppear: userNavList }"
                  >SKU Details</span
                >
              </a>
            </mat-list-item>
            <mat-list-item
              *ngIf="authGuard.isAuthorities(authorities.API_PARTNER)"
              role="listitem"
            >
              <a
                [routerLink]="['/account/loyalize-user/payments']"
                routerLinkActive="tab-active"
                class="list-item"
              >
                <img
                  src="assets/images/right-sidebar/payment.svg"
                  class="custom-icons"
                />
                <span
                  *ngIf="userNavList"
                  [ngClass]="{ textAppear: userNavList }"
                  >Payments</span
                >
              </a>
            </mat-list-item>
            <mat-list-item
              *ngIf="authGuard.isAuthorities(authorities.API_PARTNER)"
              role="listitem"
            >
              <a
                [routerLink]="['/account/loyalize-user/bank-details']"
                routerLinkActive="tab-active"
                class="list-item"
              >
                <img
                  src="assets/images/right-sidebar/bank-details.svg"
                  class="custom-icons"
                />
                <span
                  *ngIf="userNavList"
                  [ngClass]="{ textAppear: userNavList }"
                  >Bank Details</span
                >
              </a>
            </mat-list-item>
            <mat-list-item
              *ngIf="authGuard.isAuthorities(authorities.API_PARTNER)"
              role="listitem"
            >
              <a
                [routerLink]="['/account/loyalize-user/webhooks']"
                routerLinkActive="tab-active"
                class="list-item"
              >
                <img
                  src="assets/images/right-sidebar/webhook.svg"
                  class="custom-icons hook"
                />
                <span
                  *ngIf="userNavList"
                  [ngClass]="{ textAppear: userNavList }"
                  >Webhooks</span
                >
              </a>
            </mat-list-item>
            <mat-list-item
              *ngIf="authGuard.isAuthorities(authorities.API_PARTNER)"
              role="listitem"
            >
              <a
                [routerLink]="['/account/loyalize-user/api-key']"
                routerLinkActive="tab-active"
                class="list-item"
              >
                <img
                  src="assets/images/right-sidebar/api.svg"
                  class="custom-icons"
                />
                <span
                  *ngIf="userNavList"
                  [ngClass]="{ textAppear: userNavList }"
                  >API Key</span
                >
              </a>
            </mat-list-item>
            <mat-list-item
              *ngIf="!userNavList"
              role="listitem"
              (click)="openSupport()"
            >
              <a
                routerLinkActive="tab-active"
                class="list-item"
                style="cursor: pointer"
              >
                <img
                  src="assets/images/right-sidebar/support.svg"
                  class="custom-icons custom-icons-support"
                />
                <img
                  src="assets/arrow-right.png"
                  style="width: 6px; margin-top: 5px"
                />
              </a>
            </mat-list-item>

            <mat-expansion-panel
              *ngIf="userNavList"
              routerLinkActive="tab-active"
              [(expanded)]="panelOpenState"
            >
              <mat-expansion-panel-header (click)="setExpansionPanel()">
                <mat-panel-title>
                  <img
                    src="assets/images/right-sidebar/support.svg"
                    class="custom-icons custom-icons-support"
                  />
                  <span [ngClass]="{ textAppear: userNavList }"> Support</span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-list-item role="listitem">
                <a
                  [routerLink]="['/account/loyalize-user/missing-cashback']"
                  routerLinkActive="tab-active"
                  class="list-item"
                >
                  <img
                    src="assets/images/right-sidebar/report.svg"
                    class="custom-icons"
                  />
                  <span
                    *ngIf="userNavList"
                    [ngClass]="{ textAppear: userNavList }"
                    >Report Missing Cashback</span
                  >
                </a>
              </mat-list-item>
              <mat-list-item role="listitem">
                <a
                  routerLinkActive="tab-active"
                  style="cursor: pointer"
                  class="list-item"
                  target="_blank"
                  (click)="openDocumentation($event)"
                >
                  <img
                    src="assets/images/right-sidebar/api-docs.svg"
                    class="custom-icons custom-su-icons-support"
                  />
                  <span
                    *ngIf="userNavList"
                    [ngClass]="{ textAppear: userNavList }"
                    >API Docs</span
                  >
                </a>
              </mat-list-item>
              <mat-list-item role="listitem">
                <a
                  download
                  [href]="'/assets/docs/Loyalize-API-FAQ.pdf'"
                  routerLinkActive="tab-active"
                  class="list-item"
                  target="_blank"
                >
                  <img
                    src="assets/images/right-sidebar/service-desk.svg"
                    class="custom-icons custom-su-icons-support"
                  />
                  <span
                    *ngIf="userNavList"
                    [ngClass]="{ textAppear: userNavList }"
                    >API FAQ</span
                  >
                </a>
              </mat-list-item>
              <mat-list-item role="listitem">
                <a
                  href="https://loyalize.atlassian.net/servicedesk/customer/portals"
                  routerLinkActive="tab-active"
                  class="list-item"
                  target="_blank"
                >
                  <img
                    src="assets/images/right-sidebar/desk.svg"
                    class="custom-icons custom-su-icons-support higher"
                  />
                  <span
                    *ngIf="userNavList"
                    [ngClass]="{ textAppear: userNavList }"
                    >Service Desk</span
                  >
                </a>
              </mat-list-item>
              <mat-list-item role="listitem">
                <a
                  [routerLink]="['/account/loyalize-user/monitoring']"
                  routerLinkActive="tab-active"
                  class="list-item"
                >
                  <img
                    src="assets/images/right-sidebar/service.svg"
                    class="custom-icons"
                  />
                  <span
                    *ngIf="userNavList"
                    [ngClass]="{ textAppear: userNavList }"
                    >Service Monitoring</span
                  >
                </a>
              </mat-list-item>
            </mat-expansion-panel>
            <mat-list-item role="listitem">
              <a
                [routerLink]="['/account/loyalize-user/settings']"
                routerLinkActive="tab-active"
                class="list-item"
              >
                <i class="material-icons">settings</i>
                <span
                  *ngIf="userNavList"
                  [ngClass]="{ textAppear: userNavList }"
                  >Settings</span
                >
              </a>
            </mat-list-item>
          </mat-list>
          <div (click)="openCloseSidebar()" class="toggleIconButton">
            <img
              *ngIf="!userNavList"
              src="assets/svg/double-arrow-right.svg"
              class="custom-icons custom-su-icons-support mt-2"
            />
            <img
              *ngIf="userNavList"
              src="assets/svg/double-arrow-left.svg"
              class="custom-icons custom-su-icons-support mt-2"
            />
          </div>
        </div>
      </div>
      <div
        class="right-container"
        [ngClass]="{ openRight: userNavList, closeRight: !userNavList }"
      >
        <div class="container-fluid">
          <div class="tab-container">
            <div>
              <div class="header-container">
                <div class="header-title">
                  <span class="title-page">{{ loggedUser }} Dashboard</span>
                </div>
                <div class="header-user">
                  <div class="icons">
                    <app-header-user-profile-dropdown></app-header-user-profile-dropdown>
                  </div>
                </div>
              </div>
              <div class="class-content">
                <router-outlet></router-outlet>
              </div>
            </div>
            <div class="class-footer">
              <app-footer></app-footer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
